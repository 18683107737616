// This keeps running, meaning the count gets pretty high. I don't think this is really a problm
    // but it would be good to fix - Caspar
function checkScroll() {
    const termsAndConditions = document.getElementById("terms-and-conditions");
    const tcButton = document.getElementById("t-and-c-button");
    console.log("checkScroll is working");
    if (termsAndConditions && tcButton) {
        disableTermsAndConditions();
    }
};

function disableTermsAndConditions() {
    console.log("disable terms and conditions running");
    const tcButton = document.getElementById("t-and-c-button");
    jQuery(function($) {
        $('#terms-and-conditions').on('scroll', function() {
            if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
                console.log("disabling button");
                tcButton.disabled = false;
            };
        });
    });
    
}
export { checkScroll };
