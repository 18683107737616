import consumer from "./consumer";

console.log("conversation_channel.js is working");

const initConversationCable = () => {
  console.log("initConversationCable is called");
  const messagesContainer = document.getElementById('conversations-display');
  if (messagesContainer) {
    console.log("Ready to get data from the conversation");
    const id = messagesContainer.dataset.conversationId;
  
    consumer.subscriptions.create({ channel: "ConversationChannel", id: id }, {
      received(data) {
        messagesContainer.insertAdjacentHTML('beforeend', data); // called when data is broadcast in the cable
      },
    });
  }
}

export { initConversationCable };

