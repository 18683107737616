import { Controller } from "stimulus";

// export default class extends Controller {
//   static targets = ["complete"];
//   video = document.querySelector('.video')
//   connect() {
//     console.log("Hello from StimulusJS");
//     video = document.querySelector('.video')
//     this.video.addEventListener("click", (event) => completed(event));
//   }

//   completed(event) {
//     console.log(event);
//     this.completeTarget = true
//   }
// }

export default class extends Controller {
  static targets = ["complete"];
  connect() {
    console.log("Hello from StimulusJS", this.element);
    console.log(this.completeTarget.data)
  }

  completed() {
    console.log(this.completeTarget);
  }
}


//data-action="click->class#completed"
