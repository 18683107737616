 var interval = setInterval(watermark, 2000); // 10000 ms = start after 10sec
  function watermark() {
    console.log("disappear")
    // document.getElementById("watermark-id").style.display = 'none';
                   // setTimeout("location.reload(true);", 15000);

    // document.getElementById("email-watermark").style.display = 'none';
    // // hide the lorem ipsum text
    // document.getElementById("phone-watermark").style.display = 'none';
    // setTimeout(() => { console.log("Time-out"); }, 2000)
    console.log('appear')
    document.getElementById("watermark-id").style.display = 'block';
    document.getElementById("watermark-id").style.position = 'absolute';
    document.getElementById("watermark-id").style.zIndex = '5';
    document.getElementById("watermark-id").innerHTML = document.getElementById('watermark-id').innerHTML;
    // document.getElementById("email-watermark").style.display = 'block';
    // // hide the lorem ipsum text
    // document.getElementById("phone-watermark").style.display = 'block';
    // You can use native DOM methods to insert the fragment:
    interval

  }


export { watermark };
