const scrollLastMessageIntoView = () => {
  const messages = document.querySelectorAll('.received_msg');
  console.log(messages)
  const lastMessage = messages[messages.length - 1];
  console.log(lastMessage)
  if (lastMessage !== undefined) {
    lastMessage.scrollIntoView();
  }
}

export { scrollLastMessageIntoView };
