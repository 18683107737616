import consumer from "./consumer";

const initForumCable = () => {
  console.log("initForumCable called");
  const forumMessagesContainer = document.getElementById('forum-display');
  const forumMessageContent = document.getElementById('forum_message_content');
  if (forumMessagesContainer) {
    console.log("forumMessagesContainerFound");
    const id = forumMessagesContainer.dataset.forumId;
    consumer.subscriptions.create({ channel: "ForumChannel", id: id }, {
      received(data) {
        console.log("data received");
				console.log(data); // called when data is broadcast in the cable
        forumMessagesContainer.insertAdjacentHTML('afterbegin', data);
        forumMessageContent.value = '';
      },
    });
  }
}

export { initForumCable };